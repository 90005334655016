import React, { Fragment, useEffect, useState } from 'react';
import { graphql } from 'gatsby';
import {
  SEO,
  TransactionalFirstSection,
  IndustrySubMenu,
  IndustrySubSecondSection,
  IndustrySubThirdSection1,
  HomeCareer,
} from '../components';
import { SEO_TEXT } from '../constants';
import { SanityConsumerType } from '../utils/globalTypes';
import { getClient } from '../utils/sanity.client';
import { industrialMarketsQuery } from '../utils/querys/industrialMarkets/query';

interface Props {
  data: {
    sanityIndustrialMarkets: SanityConsumerType;
  };
}

const IndustrialMarkets = ({ data: { sanityIndustrialMarkets } }: Props) => {
  const [industrialMarkets, setIndustrialMarkets] = useState<any>();

  useEffect(() => {
    const url = new URL(location.href);
    const getData = async () => {
      const client = getClient({ token: process.env.GATSBY_SANITY_READ_TOKEN || '' });
      const data = await client.fetch(industrialMarketsQuery);
      setIndustrialMarkets(data);
    };
    if (industrialMarkets === undefined && url.search === '?preview_mode=true') getData();
  }, [industrialMarkets]);
  return (
    <Fragment>
      <SEO
        title={sanityIndustrialMarkets.seo?.title || ''}
        description={sanityIndustrialMarkets.seo?.description || ''}
      />
      <TransactionalFirstSection
        content={industrialMarkets ? industrialMarkets.firstSection : sanityIndustrialMarkets.firstSection}
      />
      <IndustrySubMenu activeIndex={2} />
      <IndustrySubSecondSection
        content={industrialMarkets ? industrialMarkets.secondSection : sanityIndustrialMarkets.secondSection}
      />
      <IndustrySubThirdSection1
        content={industrialMarkets ? industrialMarkets.thirdSection : sanityIndustrialMarkets.thirdSection}
        isLeftContent
      />
      <HomeCareer
        content={industrialMarkets ? industrialMarkets.fourthSection : sanityIndustrialMarkets.fourthSection}
      />
    </Fragment>
  );
};

export default IndustrialMarkets;
export const pageQuery = graphql`
  query {
    sanityIndustrialMarkets {
      seo {
        title
        keywords
        description
      }
      firstSection {
        title
        cateogry
      }
      secondSection {
        title
        _rawDescription
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
      }
      thirdSection {
        sectionItem {
          title
          _rawDescription
        }
        mainImage {
          asset {
            gatsbyImageData(fit: CROP)
          }
        }
        title
      }
      fourthSection {
        quotation
        provider
      }
    }
  }
`;
